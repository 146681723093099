<template>
  <div class="accountInfo">
    <h3>个人设置</h3>
    <ul class="infoSet">
      <li>
        <span class="label">头像</span>
        <div class="info-item">
          <img :src="userInfo.headImgUrl" alt="" class="avatar" v-if="userInfo.headImgUrl">
          <img src="https://imgtest-1257418739.cos.ap-guangzhou.myqcloud.com/userFile/512/2020-12-26/cc563b3e-ecb4-4b44-a1d8-b88a3164c4be.jpg" alt="" class="avatar" v-else>
        </div>
      </li>
      <li>
        <span class="label">昵称</span>
        <div class="info-item">
          <span>{{ userDataInfo.nickname }}</span>
        </div>
      </li>
      <li>
        <span class="label">手机</span>
        <div class="info-item">
          <div v-if="userPhone">{{ userPhone }}</div>
          <div v-else>
            <i class="bindN"></i>
            <span class="bind">未绑定</span>
            <el-button type="primary" size="mini" @click="phoneBind">绑定</el-button>
          </div>
        </div>
      </li>
      <li>
        <span class="label">QQ</span>
        <div class="info-item">
          <div v-if="userQQ">{{ userQQ }}</div>
          <div v-else>
            <i class="bindN"></i>
            <span class="bind">未绑定</span>
            <el-button type="primary" size="mini" @click="qqLogin">绑定</el-button>
          </div>
        </div>
      </li>
      <li>
        <span class="label">微信</span>
        <div class="info-item">
          <div v-if="userPublic">{{ userPublic }}</div>
          <div v-else>
            <div v-if="codeShow">
              <img :src="qrcodeUrl" alt="" class="code" @click="refresh">
            </div>
            <div v-else>
              <i class="bindN"></i>
              <span class="bind">未绑定</span>
              <el-button type="primary" size="mini" @click="getQrcode">绑定</el-button>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <el-dialog :visible.sync="isShow" width="25%" title="绑定手机" style="text-align: center">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="phone">
          <el-input v-model.number="form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-col :span="16">
            <el-form-item prop="code">
              <el-input v-model="form.code" placeholder="请输入短信验证码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              <el-button type="primary" @click="sendCode" v-if="isSend" class="sendCode">发送验证码</el-button>
              <el-button type="primary" class="sendCode" disabled v-else>{{this.countdown + 's'}}</el-button>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-button type="primary" style="width: 90%" @click="toBind">立即绑定</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo, bindPhone, bindWx } from '@/api/userInfo'
import { getPublicUrl, getPhoneCode } from '@/api/login'
import { mapState } from 'vuex'

export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入短信验证码'))
      } else {
        var reg = /^\d{6}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的短信验证码'))
        }
      }
    }
    return {
      userDataInfo: {},
      userPhone: '',
      userQQ: '',
      userPublic: '',
      qrcodeUrl: '',
      expireSeconds: '',
      code: '',
      timeId: '',
      codeShow: false,
      isShow: false,
      form: {
        phone: '',
        code: ''
      },
      isSend: true,
      countdown: '',
      rules: {
        phone: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        code: [
          { required: true, validator: validateCode, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getInfo()
  },
  computed: {
    ...mapState(['userInfo'])
  },
  watch: {
    code (val, oldval) {
      if (val === 0) {
        clearInterval(this.timeId)
        this.$message.success('绑定成功')
        this.getInfo()
      } else if (val === 666) {
        this.$message('该二维码已过期，请点击二维码刷新！')
      }
      clearInterval(this.timeId)
    }
  },
  methods: {
    // 获取用户信息
    async getInfo () {
      const res = await getUserInfo()
      if (res.code == 0) {
        this.userDataInfo = res.data
        this.getBindType(this.userDataInfo.userAuths)
      }
    },
    phoneBind () {
      this.isShow = true
    },
    // 发送验证码
    async sendCode () {
      const params = JSON.stringify(this.form)
      if (!this.form.phone) {
        this.$message('请输入正确的手机号')
      } else {
        const res = await getPhoneCode(params)
        if (res && res.code === 0) {
          this.isSend = false
          this.countdown = 60
          const codeId = setInterval(() => {
            if (this.countdown === 0) {
              clearInterval(codeId)
              this.isSend = true
            }
            this.countdown--
          }, 1000)
        }
      }
    },
    // 绑定手机
    async toBind () {
      const res = await bindPhone(this.form)
      if (res.code == 0) {
        this.$message.success('绑定成功')
        this.getInfo()
        this.isShow = false
        this.isSend = true
        this.$refs.form.resetFields()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 绑定QQ
    qqLogin () {
      const url = 'https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=' + '101927618' + '&redirect_uri=' + `${process.env.VUE_APP_QQLOGIN}?bind`
      // window.open(url, '_blank', 'width=1300, height=900')
      window.location.href = url
    },
    // 获取绑定信息
    getBindType (userAuths) {
      userAuths.forEach(item => {
        if (item.identityType == 1) {
          this.userPhone = item.identifier
        } else if (item.identityType == 3) {
          this.userPublic = JSON.parse(item.extInfo).nickname
        } else if (item.identityType == 4) {
          this.userQQ = JSON.parse(item.extInfo).nickname
        }
      })
    },
    // 获取uuid
    getUuid (uuid, isWeb) {
      const idArr = []
      const hexDigits = '0123456789abcdef'
      for (let i = 0; i < 32; i++) {
        idArr[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      if (isWeb) {
        return uuid = idArr.join('')
      } else {
        return uuid = 'web_' + idArr.join('')
      }
    },
    // 获取二维码
    async getQrcode () {
      this.codeShow = true
      this.uuid = this.getUuid(this.uuid)
      const res = await getPublicUrl({ sceneId: this.uuid })
      if (res.code == 0) {
        this.expireSeconds = new Date().getTime() + res.data.expireSeconds * 1000
        this.qrcodeUrl = res.data.qrCode
        this.sweepCode()
      }
    },
    // 扫码
    async sweepCode () {
      this.timeId = setInterval(async () => {
        const res = await bindWx({ sceneId: this.uuid })
        if (res.code == 0) {
          this.code = 0
        } else if (res.code == 500) {
          this.$message.error('该微信已绑定其他账号')
          this.refresh()
        } else {
          if (new Date().getTime() > this.expireSeconds) {
            this.code = 666
          }
        }
      }, 5000)
    },
    // 刷新二维码
    refresh () {
      clearInterval(this.timeId)
      this.getQrcode()
    }
  },
  beforeRouteLeave (to, from, next) {
    next()
    if (this.timeId) {
      clearInterval(this.timeId)
      this.timeId = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .accountInfo {
    padding: 30px;
    h3 {
      padding: 20px 0;
      border-bottom: 1px solid #ccc;
    }
    .infoSet {
      li {
        font-size: 14px;
        display: flex;
        align-items: center;
        .avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        .code {
          width: 150px;
          height: 150px;
        }
        .label {
          width: 50px;
        }
        .bindN {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ccc;
        }
        .info-item {
          border-bottom: 1px solid #ccc;
          width: 80%;
          padding: 40px 0;
          margin-left: 50px;
        }
        .bind {
          margin: 0 50px 0 10px;
        }
      }
    }
    .sendCode {
      width: 100%;
      margin-left: 12px;
    }
    .code {
      width: 145px;
      cursor: pointer;
    }
  }
</style>
